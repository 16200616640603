import React, { useEffect } from "react";
import cx from "classnames";
import { graphql } from "gatsby";

import { FULL_VIEW_WIDTH, HALF_VIEW_WIDTH } from "../../../configs/images";

import {
  DesignConsultationForm,
  HostApplicantForm,
  TradeProgramForm,
  NBSForm,
  ImageContainer,
} from "../../components";
import { useFormsContext } from "../../contexts/Forms/Forms.context";
import MainLayout from "../../hoc/Layouts/MainLayout";
import { formatMarkdown, scrollToTop } from "../../utils/helpers";
import { buildComponentStack } from "../../utils/StackBuilder/StackBuilder";

import * as styles from "./FormPage.module.scss";

interface FormPageData {
  primary: string;
  secondary?: string;
  body?: string;
  klaviyoList?: string;
  image?: {
    url: string;
  };
  formTitle?: string;
  formType?: "host" | "visit" | "connect";
  formAlignment?: "left" | "right";
  formConfirmation?: {
    primary: string;
    secondary?: string;
    body?: string;
    squareCtas?: {
      primary: string;
      link: string;
      backgroundImage: {
        url: string;
      };
    };
  };
  locale?: string;
}

interface FormPageProps {
  location: any;
  pageContext: any;
  data: {
    alamedaapi: {
      formPages: FormPageData;
    };
  };
}

const RenderForm = ({ formType, klaviyoList, formConfirmation }, location) => {
  switch (formType) {
    case "host":
      return <HostApplicantForm klaviyoList={klaviyoList} />;
    case "connect":
    case "visit":
      return (
        <NBSForm
          location={location}
          type={formType}
          klaviyoList={klaviyoList}
          confirmationContent={formConfirmation}
        />
      );
    case "design":
      return (
        <DesignConsultationForm
          type={formType}
          klaviyoList={klaviyoList}
          confirmationContent={formConfirmation}
        />
      );
    case "tradeProgram":
      return (
        <TradeProgramForm
          type={formType}
          klaviyoList={klaviyoList}
          confirmationContent={formConfirmation}
        />
      );
    default:
      return null;
  }
};

const FormPage = ({
  location,
  data: {
    alamedaapi: { formPages },
  },
  pageContext,
}: FormPageProps) => {
  const {
    state: { step },
    dispatch,
  } = useFormsContext();
  const formPage = formPages[0];

  useEffect(() => {
    dispatch({ type: "reset" });
  }, []);

  const componentStack = buildComponentStack(formPage.stack);

  useEffect(() => {
    scrollToTop();
  }, [step]);

  return (
    <MainLayout pageContext={pageContext}>
      <div>{componentStack}</div>

      <div className={cx({ "flex w-full flex-col md:flex-row": step === 0 })}>
        {step === 0 && (
          <div className="grow basis-0">
            <div className="component-default-padding">
              <h1 className="mb-8 text-2xl md:text-4xl lg:text-5xl">
                {formPage.primary}
              </h1>
              {formPage.secondary && (
                <div
                  className="mb-8 text-xl text-secondary-color lg:text-2xl"
                  dangerouslySetInnerHTML={{
                    __html: formatMarkdown(formPage.secondary),
                  }}
                />
              )}
              {formPage.body && (
                <div
                  className={styles.body}
                  dangerouslySetInnerHTML={{
                    __html: formatMarkdown(formPage.body),
                  }}
                />
              )}
              {formPage.image && (
                <div className={styles.image}>
                  <ImageContainer
                    src={formPage.image.url}
                    ratio={2 / 3}
                    sizes={`(max-width: 768px) ${FULL_VIEW_WIDTH}, ${HALF_VIEW_WIDTH}`}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        <div
          className={cx({
            "grow basis-0 bg-outer-light-gray px-4 py-16 md:p-0": step === 0,
          })}
        >
          <div
            className={cx({
              "mx-auto md:my-32 md:w-10/12 lg:w-2/3": step === 0,
            })}
          >
            {step === 0 && (
              <div className="mb-10 ">
                <h2 className="mb-4 text-2xl">{formPage.formTitle}</h2>
                <p className="text-sm text-primary-color">* Required</p>
              </div>
            )}
            {RenderForm(formPage, location)}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default FormPage;

export const formPageQuery = graphql`
  query FORM_PAGE_QUERY($locale: String!, $formPageId: ID!) {
    alamedaapi {
      formPages(locale: $locale, where: { id: $formPageId }) {
        primary
        secondary
        body
        image {
          url
          formats
        }
        formAlignment
        klaviyoList
        formTitle
        formType
        formConfirmation {
          primary
          secondary
          body
          squareCtas {
            primary
            link
            backgroundImage {
              url
              formats
            }
          }
        }
        stack {
          __typename
          ... on AMAPI_ComponentStackSeo {
            ...ComponentStackSeoFields
          }
        }
      }
    }
  }
`;
